export const getMoneyColour = (balance) => {
  let colour = "black";
  if (balance > 0) {
    colour = "green";
  } else if (balance < 0) {
    colour = "red";
  }
  return colour;
};

export const roundFloat = (num: number) => {
  return parseFloat(num.toFixed(2));
};

export const getApiUrl = (env) => {
  switch (env) {
    case "local":
      return process.env.REACT_APP_API_URL_LOCAL;
    case "development":
      return process.env.REACT_APP_API_URL_DEV;
    case "production":
      return process.env.REACT_APP_API_URL_PROD;
    default:
      throw new Error("Environment not set.");
  }
};

export const state = {
  LOADING: "LOADING",
  READY: "READY",
  UPDATING: "UPDATING",
  ERROR: "ERROR",
  AWAITING_APPROVAL: "AWAITING_APPROVAL",
  FINALISE_SIGNUP: "FINALISE_SIGNUP",
};
