import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import { useHistory } from "react-router-dom";
import EmptyProfilePic from "../../assets/images/profile-empty.jpg";

function PlayersDialog(props) {
  const history = useHistory();
  const {
    selectedGameId,
    onClose,
    playersAttending,
    playersOnGameDialogOpen,
    handleDeleteAttendance,
    attendance,
    players,
    addPlayerToGame,
    isAdmin,
  } = props;

  function deleteAttendancePressed(playerid) {
    handleDeleteAttendance(playerid);
  }

  async function handlePlayerSelected(playerid) {
    await addPlayerToGame(playerid, selectedGameId);
  }

  const playersOnGame = attendance
    .filter((atten) => atten.gameid === selectedGameId)
    .map((atten) => {
      return players.find((player) => player._id === atten.playerid);
    });
  const playersOffGame = players.filter(
    (player) => !playersOnGame.map((p) => p._id).includes(player._id)
  );

  return (
    <Dialog fullWidth onClose={onClose} open={playersOnGameDialogOpen}>
      <DialogTitle>{playersAttending.length} Players Attending</DialogTitle>
      <DialogContent>
        <List>
          {playersAttending.map((player) => {
            return (
              <div key={player.id}>
                <ListItem
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push(`/profile/${player.id}`);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={player.picture ? player.picture : EmptyProfilePic}
                    ></Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={player.name}
                    secondary={player.position}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      style={{ display: isAdmin ? "" : "none" }}
                      onClick={() => deleteAttendancePressed(player.id)}
                      edge="end"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>

                <Divider />
              </div>
            );
          })}
        </List>
        <div style={{ display: isAdmin ? "" : "none" }}>
          <Typography variant="h6">Add Player To Game</Typography>
          <FormControl fullWidth>
            <InputLabel id="position-input-label">Player</InputLabel>
            <Select
              value=""
              displayEmpty
              labelId="position-input-label"
              id="position-select"
              onChange={(e) => {
                handlePlayerSelected(e.target.value);
              }}
            >
              {playersOffGame.map((player) => {
                return (
                  <MenuItem key={player._id} value={player._id}>
                    {player.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default PlayersDialog;
