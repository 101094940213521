import { ThemeProvider } from "@material-ui/styles";
import LogRocket from "logrocket";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import App from "./App";
import "./index.css";
import { Auth0Provider } from "./react-auth0-spa";
import * as serviceWorker from "./serviceWorker";
import { SnackbarProvider } from "notistack";
import Theme from "./Theme";
import history from "./utils/history";
import * as Sentry from "./services/Sentry";
import PrivacyPolicyPage from "./components/Common/PrivacyPolicyPage";

Sentry.initSentry();
LogRocket.init(process.env.REACT_APP_LOGROCKET);

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/privacy">
        <PrivacyPolicyPage />
      </Route>
      <Route>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          client_id={process.env.REACT_APP_AUTH0_APP_CLIENT_ID}
          redirect_uri={window.location.origin}
          onRedirectCallback={onRedirectCallback}
          useRefreshTokens={true}
          audience={process.env.REACT_APP_AUTH0_AUDIENCE}
          scope={process.env.REACT_APP_AUTH0_SCOPE}
        >
          <ThemeProvider theme={Theme}>
            <SnackbarProvider>
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </Auth0Provider>
      </Route>
    </Switch>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
