import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import React from "react";
import { useHistory } from "react-router-dom";

function UnactivePlayers(props) {
  const { unactivePlayers, rejectPlayer, activatePlayer } = props;
  const history = useHistory();

  async function handleRejectPressed(playerid) {
    await rejectPlayer(playerid);
  }

  async function handleAcceptPressed(playerid) {
    await activatePlayer(playerid);
  }

  return (
    <Grid container spacing={3}>
      {unactivePlayers.map((player) => {
        return (
          <Grid container spacing={3} item xs={12} key={player._id}>
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar
                onClick={() => history.push(`/profile/${player._id}`)}
                src={player.picture}
              />
            </Grid>
            <Grid
              item
              xs={8}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                component="span"
                variant="body2"
              >
                {player.name}
              </Typography>
              <Typography
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                component="span"
                variant="body2"
              >
                {player.email}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <IconButton onClick={() => handleAcceptPressed(player._id)}>
                <CheckIcon style={{ color: "Green" }} />
              </IconButton>
              <IconButton onClick={() => handleRejectPressed(player._id)}>
                <ClearIcon style={{ color: "Red" }} />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );

  // return (
  //   <List>
  //     {unactivePlayers.map((player) => {
  //       return (
  //         <div key={player._id}>
  //           <ListItem alignItems="flex-start">
  //             <ListItemAvatar>
  //               <Avatar src={player.picture} />
  //             </ListItemAvatar>
  //             <ListItemText
  //               primary={player.name}
  //               onClick={() => history.push(`/profile/${player._id}`)}
  //               secondary={
  //                 <>
  //                   <Typography component="span" variant="body2">
  //                     {player.email}
  //                   </Typography>
  //                 </>
  //               }
  //             />

  //             <ListItemSecondaryAction>
  //               <IconButton onClick={() => handleAcceptPressed(player._id)}>
  //                 <CheckIcon style={{ color: "Green" }} />
  //               </IconButton>
  //               <IconButton onClick={() => handleRejectPressed(player._id)}>
  //                 <ClearIcon style={{ color: "Red" }} />
  //               </IconButton>
  //             </ListItemSecondaryAction>
  //           </ListItem>
  //           <Divider variant="inset" />
  //         </div>
  //       );
  //     })}
  //   </List>
  // );
}

export default UnactivePlayers;
