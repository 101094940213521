import React from "react";
import PlayersList from "./PlayersList";
import FullWidthPaper from "../Common/PageTemplates/FullWidthPaper";
import { GlobalStateConsumer } from "../../services/GlobalStateContext";
import UnactivePlayers from "./UnactivePlayers";

function PlayersContainer() {
  return (
    <GlobalStateConsumer>
      {({ state, activatePlayer, rejectPlayer }) => {
        const { players, transactions, profile } = state;
        const activePlayers = players.filter((player) => player.active);
        const unactivePlayers = players.filter((player) => !player.active);
        return (
          <>
            <FullWidthPaper heading="Players">
              <PlayersList
                players={activePlayers}
                transactions={transactions}
              />
            </FullWidthPaper>
            <FullWidthPaper hide={!profile.admin} heading="Awaiting Approval">
              <UnactivePlayers
                unactivePlayers={unactivePlayers}
                rejectPlayer={rejectPlayer}
                activatePlayer={activatePlayer}
              />
            </FullWidthPaper>
          </>
        );
      }}
    </GlobalStateConsumer>
  );
}

export default PlayersContainer;
