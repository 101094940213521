import {
  Avatar,
  DialogContentText,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import { withSnackbar } from "notistack";
import React, { useState } from "react";
import EmptyProfilePic from "../../assets/images/profile-empty.jpg";
import * as utils from "../../utils/utils";

function SpendMoneyDialog(props) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(0);
  const [playersSelected, setPlayersSelected] = useState([]);
  const { spendMoney, enqueueSnackbar, players } = props;

  const activePlayers = players.filter((player) => player.active);

  const handlePlayerRemoved = (playerid) => {
    setPlayersSelected(
      playersSelected.filter((player) => player._id !== playerid)
    );
  };
  const handlePlayerSelected = (playerid) => {
    const selectedPlayer = activePlayers.find(
      (player) => player._id === playerid
    );
    setPlayersSelected([...playersSelected, selectedPlayer]);
  };

  const handleClickOpen = () => {
    setName("");
    setAmount(0);
    setPlayersSelected([]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSpendMoney = async () => {
    const playersSelectedIds = playersSelected.map((player) => player._id);
    const spendResult = await spendMoney(playersSelectedIds, amount, name);
    if (spendResult > 0) {
      enqueueSnackbar("Transactions created.");
    } else {
      enqueueSnackbar("Failed to create transactions.");
    }
    handleClose();
  };

  const playersSelectedIds = playersSelected.map((player) => player._id);

  const playersNotOnGame = activePlayers.filter(
    (player) => !playersSelectedIds.includes(player._id)
  );

  const validInput = name && amount > 0 && playersSelected.length > 0;

  const splitMessage =
    playersSelectedIds.length > 0
      ? `Splitting $${amount} with ${
          playersSelectedIds.length
        } players at $${utils.roundFloat(
          amount / playersSelectedIds.length
        )} each.`
      : "Add players to split amount.";

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        fullWidth
      >
        Spend Money
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Spend Money Details</DialogTitle>
        <DialogContent>
          <DialogContentText>{splitMessage}</DialogContentText>
          <TextField
            autoFocus
            fullWidth
            label="Description or name of transaction"
            placeholder="New ball cost..."
            type="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            margin="dense"
            id="cost"
            label="Amount"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            value={amount > 0 ? amount : ""}
            onChange={(e) => setAmount(Number(e.target.value))}
            fullWidth
          />

          <FormControl fullWidth>
            <InputLabel>Add Player</InputLabel>
            <Select
              value=""
              displayEmpty
              onChange={(e) => {
                handlePlayerSelected(e.target.value);
              }}
            >
              {playersNotOnGame.map((player) => {
                return (
                  <MenuItem key={player._id} value={player._id}>
                    {player.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <List>
            {playersSelected.map((player) => {
              return (
                <ListItem key={player._id} alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar
                      alt={player.name}
                      src={player.picture ? player.picture : EmptyProfilePic}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={player.name}
                    secondary={player.position}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={(e) => handlePlayerRemoved(player._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!validInput}
            onClick={() => handleSpendMoney()}
            color="primary"
          >
            Spend
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withSnackbar(SpendMoneyDialog);
