import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { format } from "date-fns";
import { withSnackbar } from "notistack";
import React, { useState } from "react";

function CreateGameDialog(props) {
  const [open, setOpen] = useState(false);
  const [gameTime, setGameTime] = useState(format(new Date(), "HH:MM"));
  const [gameDate, setGameDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [venueID, setVenueID] = useState("");
  const [gameName, setGameName] = useState("Weekly game");
  const { enqueueSnackbar, venues, createGame } = props;

  const activeVenues = venues.filter((venue) => venue.active);

  const handleClickOpen = () => {
    setGameTime(format(new Date(), "HH:MM"));
    setGameDate(format(new Date(), "yyyy-MM-dd"));
    setVenueID("");
    setGameName("Weekly game");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreatePressed = async () => {
    const result = await createGame(gameName, gameDate, gameTime, venueID);
    if (result) {
      enqueueSnackbar("Game created.");
      handleClose();
    } else {
      console.error(result);
      enqueueSnackbar("Error creating game.");
    }
  };

  const validInput = venueID && gameName && gameDate && gameTime;

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        fullWidth
      >
        Create Game
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create Game</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            value={gameName}
            onChange={(e) => setGameName(e.target.value)}
            fullWidth
          />

          <TextField
            id="date"
            label="Date"
            type="date"
            value={gameDate}
            onChange={(e) => setGameDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            id="time"
            label="Time"
            type="time"
            value={gameTime}
            onChange={(e) => setGameTime(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />

          <FormControl fullWidth>
            <InputLabel id="position-input-label">Venue</InputLabel>
            <Select
              labelId="position-input-label"
              id="position-select"
              value={venueID}
              onChange={(e: any) => setVenueID(e.target.value)}
            >
              {activeVenues.map((venue) => {
                return (
                  <MenuItem key={venue._id} value={venue._id}>
                    {venue.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!validInput}
            onClick={handleCreatePressed}
            color="primary"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withSnackbar(CreateGameDialog);
