import {
  Badge,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PeopleIcon from "@material-ui/icons/People";
import { format, isBefore } from "date-fns";
import { withSnackbar } from "notistack";
import React, { useState } from "react";
import FadeIn from "react-fade-in";
import Reward from "react-rewards";
import PlayersDialog from "./PlayersDialog";

const useStyles = makeStyles((theme) => ({
  attending: {
    background: "Green",
    color: "White",
    "&:hover": {
      background: "#004800",
    },
  },
  notAttending: {
    background: "Red",
    color: "White",
    "&:hover": {
      background: "#961f16",
    },
  },
  spaceTop: {
    marginTop: "5%",
  },
  spaceBottom: {
    marginBottom: "5%",
  },
  title: {
    padding: "1% 3%",
  },
}));

const rewardRefArray = [];
let selectedGameId = null;
function UpcomingGames(props) {
  const [updating, setUpdating] = useState(false);
  const [playersOnGameDialogOpen, setPlayersOnGameDialogOpen] = useState(false); // Players dialog box
  const classes = useStyles({});
  const {
    enqueueSnackbar,
    upcomingGames,
    profile,
    attendance,
    venues,
    players,
    addPlayerToGame,
    removePlayerFromGame,
  } = props;

  const sortedUpcomingGames = upcomingGames.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    if (isBefore(dateB, dateA)) {
      return 1;
    } else {
      return -1;
    }
  });

  const playersAttending = attendance
    .filter((att) => att.gameid === selectedGameId)
    .map((att) => {
      const playerLookup = players.find((p) => p._id === att.playerid);
      return {
        name: playerLookup.name,
        position: playerLookup.position,
        picture: playerLookup.picture,
        id: playerLookup._id,
      };
    });

  const isAdmin = profile.admin;

  async function handleDeleteAttendance(playerid) {
    await removePlayerFromGame(playerid, selectedGameId);
  }

  function handlePlayersOnGameDialogClosed() {
    setPlayersOnGameDialogOpen(false);
  }

  async function handleSignupPressed(game) {
    setUpdating(true);
    await addPlayerToGame(profile._id, game._id);
    rewardRefArray[game._id].rewardMe();
    enqueueSnackbar(`Signed up to ${game.name}`);
    setUpdating(false);
  }

  async function handleWidthdrawPressed(game) {
    setUpdating(true);
    await removePlayerFromGame(profile._id, game._id);
    enqueueSnackbar(`Withdrew from ${game.name}`);
    setUpdating(false);
  }

  function playersCountPressed(gameid) {
    selectedGameId = gameid;
    setPlayersOnGameDialogOpen(true);
  }

  if (sortedUpcomingGames.length === 0) {
    return <div>No upcoming games scheduled :(</div>;
  }

  return (
    <List style={{ width: "100%" }}>
      <FadeIn>
        {sortedUpcomingGames.map((game) => {
          const playersAttendingGame = attendance.filter(
            (att) => att.gameid === game._id
          );
          const playerLookup = playersAttendingGame.find(
            (att) => att.playerid === profile._id
          );

          const isPlayerAttending = playerLookup ? true : false;
          const venue = venues.find((venue) => venue._id === game.venue);

          const gameDate = `${format(
            new Date(game.date),
            "eeee - dd MMM yyyy"
          )}`;
          const gameTime = `${format(new Date(game.date), "p")}`;

          return (
            <div key={game._id}>
              <ListItem disableGutters>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={3}
                  style={{ padding: "0 2%" }}
                >
                  <Grid
                    item
                    md={9}
                    xs={8}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h6">{game.name}</Typography>
                    <Typography variant="body1">{venue.name}</Typography>
                    <Typography variant="body2">{gameDate}</Typography>
                    <Typography variant="body2">{gameTime}</Typography>
                  </Grid>

                  <Grid
                    item
                    md={3}
                    xs={4}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Grid item container spacing={3}>
                      <Grid item xs={12}>
                        <Reward
                          ref={(ref) => {
                            rewardRefArray[game._id] = ref;
                          }}
                          type="confetti"
                          config={{
                            lifetime: 400,
                            angle: 110,
                            spread: 80,
                            elementCount: 80,
                            springAnimation: false,
                          }}
                        >
                          <Button
                            className={classes.attending}
                            disabled={isPlayerAttending || updating}
                            variant="contained"
                            onClick={() => handleSignupPressed(game)}
                            fullWidth
                          >
                            Signup
                          </Button>
                        </Reward>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          className={classes.notAttending}
                          disabled={!isPlayerAttending || updating}
                          variant="contained"
                          onClick={(e) => handleWidthdrawPressed(game)}
                          fullWidth
                        >
                          Withdraw
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Badge
                          badgeContent={playersAttendingGame.length.toString()}
                          color="primary"
                          showZero
                          onClick={() => playersCountPressed(game._id)}
                        >
                          <PeopleIcon />
                        </Badge>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
            </div>
          );
        })}

        <PlayersDialog
          selectedGameId={selectedGameId}
          playersOnGameDialogOpen={playersOnGameDialogOpen}
          onClose={handlePlayersOnGameDialogClosed}
          playersAttending={playersAttending}
          handleDeleteAttendance={handleDeleteAttendance}
          attendance={attendance}
          players={players}
          addPlayerToGame={addPlayerToGame}
          removePlayerFromGame={removePlayerFromGame}
          isAdmin={isAdmin}
        />
      </FadeIn>
    </List>
  );
}
export default withSnackbar(UpcomingGames);
