import { createMuiTheme } from "@material-ui/core/styles";

// Colours:
// https://coolors.co/1e3d58-056dcd-43b0f1-e8eef1-d1ccdc

const NAVY = "#1E3D58";
//const BLUE = "#056DCD";
const LIGHT_BLUE = "#43B0F1";
const WHITE = "#E8EEF1";
//const LIGHT_PURPLE = "#D1CCDC";
const theme = createMuiTheme({
  props: {
    // Disable radio button ripple
    MuiRadio: {
      disableRipple: true
    }
  },
  palette: {
    primary: {
      main: NAVY
    },
    secondary: {
      main: LIGHT_BLUE
    },
    text: {
      main: WHITE
    }
  }
});

export default theme;
