import React from "react";
import { List, ListItem, Grid, Divider } from "@material-ui/core";
import { format } from "date-fns";

function PaymentsList(props) {
  const { payments } = props;
  return (
    <List>
      {payments.map((payment) => {
        return (
          <div key={payment._id}>
            <ListItem disableGutters>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {payment.name}
                </Grid>
                <Grid item xs={6} style={{ color: "Green" }}>
                  ${parseFloat(payment.amount).toFixed(2)}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {`${format(new Date(payment.createdAt), "d/MM/yyyy")}`}
                </Grid>
              </Grid>
            </ListItem>
            <Divider />
          </div>
        );
      })}
    </List>
  );
}

export default PaymentsList;
