import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useHistory } from "react-router-dom";
import * as utils from "../../utils/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: "inline",
    },
  })
);

function PlayerList(props) {
  const { players, transactions } = props;
  const history = useHistory();
  const classes = useStyles({});

  const overallBalance = transactions.reduce((total, t) => {
    return total + t.amount;
  }, 0);

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <List className={classes.root}>
          {players
            .sort((a, b) =>
              parseFloat(a.balance) > parseFloat(b.balance) ? 1 : -1
            )
            .map((player) => {
              return (
                <div key={player._id}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar src={player.picture} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={player.name}
                      onClick={() => history.push(`/profile/${player._id}`)}
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            {player.position}
                          </Typography>
                        </>
                      }
                    />

                    <ListItemSecondaryAction
                      style={{ color: utils.getMoneyColour(player.balance) }}
                    >
                      ${player.balance}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider variant="inset" />
                </div>
              );
            })}
        </List>
      </Grid>
      <Grid container item xs={12} md={6} justify="center">
        <Grid item xs={12} md={6}>
          <List dense>
            <ListItem key={1}>
              <ListItemText primary="Current Balance" />
              <ListItemSecondaryAction
                style={{ color: utils.getMoneyColour(overallBalance) }}
              >
                ${overallBalance.toFixed(2)}
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PlayerList;
