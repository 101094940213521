import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

function EditVenueDialog(props) {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [isActive, setIsActive] = useState(true);
  const { venueToEdit, isEditVenueDialogOpen, handleEditVenueClosed } = props;

  useEffect(() => {
    if (venueToEdit) {
      setName(venueToEdit.name);
      setAddress(venueToEdit.address);
      setPhone(venueToEdit.phone);
      setIsActive(venueToEdit.active);
    }
  }, [venueToEdit]);

  function handleClosePressed(confirmSave) {
    if (confirmSave) {
      const newVenueState = {
        ...venueToEdit,
        name,
        address,
        phone,
        active: isActive,
      };
      handleEditVenueClosed(confirmSave, newVenueState);
    } else {
      handleEditVenueClosed(confirmSave);
    }
  }

  return (
    <Dialog
      fullWidth
      open={isEditVenueDialogOpen}
      //   onClick={() => handleClosePressed(false)}
    >
      <DialogTitle>{`Editing ${venueToEdit?.name}`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Name"
              fullWidth
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address"
              label="Address"
              value={address}
              fullWidth
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="phone"
              label="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Switch color="primary" />}
              checked={isActive}
              onChange={(event, newValue) => {
                setIsActive(newValue);
              }}
              label="Active"
              labelPlacement="top"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClosePressed(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => handleClosePressed(true)}
          color="primary"
          autoFocus
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditVenueDialog;
