import { Divider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { format, isBefore } from "date-fns";
import { withSnackbar } from "notistack";
import React, { useState } from "react";
import Reward from "react-rewards";
import { state } from "../../utils/utils";
import FinaliseGameDialog from "../Admin/FinaliseGameDialog";
import ConfirmDeleteGameDialog from "./ConfirmDeleteGameDialog";
import EditGameDialog from "./EditGameDialog";

const rewardRefArray = [];
let gameToDelete = null;
let gameToFinalise = null;
let gameToEdit = null;
function GamesList(props) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isFinaliseDialogOpen, setIsFinaliseDialogOpen] = useState(false);
  const [isEditGameDialogOpen, setIsEditGameDialogOpen] = useState(false);
  const {
    enqueueSnackbar,
    games,
    attendance,
    profile,
    venues,
    players,
    addPlayerToGame,
    removePlayerFromGame,
    deleteGame,
    finaliseGame,
    updateGame,
  } = props;
  const [status, setStatus] = useState(state.READY);

  async function handleEditGameClosed(confirmSave, newGameState) {
    if (confirmSave) {
      const result = await updateGame(newGameState);
      if (result) {
        enqueueSnackbar(`Updated ${newGameState.name}`);
      }
    }
    setIsEditGameDialogOpen(false);
    gameToEdit = null;
  }

  function handleEditGamePressed(game) {
    gameToEdit = game;
    setIsEditGameDialogOpen(true);
  }

  function handleDeletePressed(game) {
    setIsDeleteDialogOpen(true);
    gameToDelete = game;
  }

  async function handleDeleteClosed(confirmDelete) {
    if (confirmDelete) {
      setStatus(state.UPDATING);
      const gameName = gameToDelete.name;
      const gameId = gameToDelete._id;
      await deleteGame(gameId);
      enqueueSnackbar(`Deleted ${gameName}`);
      setStatus(state.READY);
    }
    setIsDeleteDialogOpen(false);
    gameToDelete = null;
  }

  function handleFinalisePressed(game) {
    gameToFinalise = game;
    setIsFinaliseDialogOpen(true);
  }

  async function handleFinaliseClosed() {
    setIsFinaliseDialogOpen(false);
    gameToFinalise = null;
  }

  return (
    <>
      <Grid container spacing={3}>
        {games.map((game) => {
          const playersAttendingGame = attendance.filter(
            (att) => att.gameid === game._id
          );
          const playerLookup = playersAttendingGame.find(
            (att) => att.playerid === profile._id
          );

          const isPlayerAttending = playerLookup ? true : false;
          const venue = venues.find((venue) => venue._id === game.venue);

          const startDate = format(new Date(game.date), "eeee - dd MMM yyyy");
          const startTime = format(new Date(game.date), "p");

          const hideIfNotAdmin = { display: profile.admin ? "" : "none" };

          const gameInPast = isBefore(new Date(game.date), new Date());
          const hideIfInThePast = { display: gameInPast ? "none" : "" };

          const buttonStyle = { margin: "5px 15px" };
          return (
            <React.Fragment key={game._id}>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h6">{game.name}</Typography>
                  <Typography variant="body2">{venue.name}</Typography>
                  <Typography variant="body2">{startDate}</Typography>
                  <Typography variant="body2">{startTime}</Typography>
                </Grid>

                <Grid item xs={12} container style={buttonStyle}>
                  <Grid
                    item
                    style={{
                      display: gameInPast ? "none" : "",
                      ...buttonStyle,
                    }}
                  >
                    <Reward
                      ref={(ref) => {
                        rewardRefArray[game._id] = ref;
                      }}
                      type="confetti"
                      config={{
                        lifetime: 400,
                        angle: 110,
                        spread: 80,
                        elementCount: 80,
                        springAnimation: false,
                      }}
                    >
                      <Button
                        // className={classes.attending}
                        variant="outlined"
                        disabled={
                          isPlayerAttending || status === state.UPDATING
                        }
                        onClick={async () => {
                          setStatus(state.UPDATING);
                          await addPlayerToGame(profile._id, game._id);
                          rewardRefArray[game._id].rewardMe();
                          enqueueSnackbar(`Signed up to ${game.name}`);
                          setStatus(state.READY);
                        }}
                        fullWidth
                      >
                        Signup
                      </Button>
                    </Reward>
                  </Grid>

                  <Grid
                    item
                    style={{
                      display: gameInPast ? "none" : "",
                      ...buttonStyle,
                    }}
                  >
                    <Button
                      variant="outlined"
                      disabled={!isPlayerAttending || status === state.UPDATING}
                      onClick={async () => {
                        setStatus(state.UPDATING);
                        await removePlayerFromGame(profile._id, game._id);
                        enqueueSnackbar(`Withdrew from ${game.name}`);
                        setStatus(state.READY);
                      }}
                      fullWidth
                    >
                      Withdraw
                    </Button>
                  </Grid>

                  <Grid
                    item
                    style={{
                      ...hideIfInThePast,
                      ...buttonStyle,
                      ...hideIfNotAdmin,
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={() => {
                        handleDeletePressed(game);
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                  <Grid
                    item
                    style={{
                      ...hideIfInThePast,
                      ...buttonStyle,
                      ...hideIfNotAdmin,
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={() => handleEditGamePressed(game)}
                    >
                      Edit
                    </Button>
                  </Grid>
                  <Grid
                    item
                    style={{
                      display:
                        gameInPast && !game.finalised && profile.admin
                          ? ""
                          : "none",
                    }}
                  >
                    <Button
                      disabled={!gameInPast}
                      color="primary"
                      onClick={() => handleFinalisePressed(game)}
                    >
                      Finalise
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
      <ConfirmDeleteGameDialog
        isDeleteDialogOpen={isDeleteDialogOpen}
        handleDeleteClosed={handleDeleteClosed}
      />
      <FinaliseGameDialog
        isFinaliseDialogOpen={isFinaliseDialogOpen}
        handleFinaliseClosed={handleFinaliseClosed}
        selectedGame={gameToFinalise}
        attendance={attendance}
        players={players}
        games={games}
        finaliseGame={finaliseGame}
      />
      <EditGameDialog
        gameToEdit={gameToEdit}
        isEditGameDialogOpen={isEditGameDialogOpen}
        handleEditGameClosed={handleEditGameClosed}
        venues={venues}
      />
    </>
  );
}

export default withSnackbar(GamesList);
