import React from "react";
import VenuesList from "./VenuesList";
import FullWidthPaper from "../Common/PageTemplates/FullWidthPaper";
import { GlobalStateConsumer } from "../../services/GlobalStateContext";
import Grid from "@material-ui/core/Grid";
import CreateVenueDialog from "./CreateVenueDialog";
import { Divider } from "@material-ui/core";

function VenuesContainer(props) {
  return (
    <GlobalStateConsumer>
      {({ state, updateVenue, createVenue }) => {
        const { venues, profile } = state;
        const hideIfNotAdmin = { display: profile.admin ? "" : "none" };

        return (
          <>
            <FullWidthPaper heading="Venues">
              <Grid container>
                <Grid item xs={12} md={2} style={hideIfNotAdmin}>
                  <CreateVenueDialog createVenue={createVenue} />
                </Grid>
                <Grid item xs={12} style={hideIfNotAdmin}>
                  <Divider style={{ margin: "12px 0" }} />
                </Grid>
                <Grid item xs={12}>
                  <VenuesList venues={venues} updateVenue={updateVenue} />
                </Grid>
              </Grid>
            </FullWidthPaper>
          </>
        );
      }}
    </GlobalStateConsumer>
  );
}

export default VenuesContainer;
