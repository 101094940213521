import React from "react";
import { Container, Paper, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  heading: {
    marginTop: "20px"
  },
  paper: {
    padding: "2%",
    marginTop: "20px",
    marginBottom: "20px"
  }
}));

function FullWidthPaper({ children, heading, hide = false }) {
  const classes = useStyles({});
  if (hide) {
    return null;
  }
  return (
    <Grid container className="FullWidthTemplate">
      <Grid item xs={12}>
        <Typography className={classes.heading} variant="h2">
          {heading}
        </Typography>
      </Grid>
      <Container disableGutters>
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={6}>
            {children}
          </Paper>
        </Grid>
      </Container>
    </Grid>
  );
}

export default FullWidthPaper;
