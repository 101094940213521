export const actions = {
    // General
    UPDATE_STATUS: "UPDATE_STATUS",
  
    // Global state context
    INITIAL_DATA_LOADED: "INITIAL_DATA_LOADED",
    NEW_PLAYER_SIGNUP: "NEW_PLAYER_SIGNUP",
    ADD_PLAYER_TO_GAME: "ADD_PLAYER_TO_GAME",
    REMOVE_PLAYER_FROM_GAME: "REMOVE_PLAYER_FROM_GAME",
    EDIT_PLAYER: "EDIT_PLAYER",
    DELETE_PLAYER: "DELETE_PLAYER",
    PROFILE_UPDATED: "PROFILE_UPDATED",
    GAME_CREATED: "GAME_CREATED",
    GAME_DELETED: "GAME_DELETED",
    GAME_UPDATED: "GAME_UPDATED",
    VENUE_UPDATED: "VENUE_UPDATED",
    VENUE_CREATED: "VENUE_CREATED",
    VENUE_DELETED: "VENUE_DELETED",
    PAYMENT_DETAILS_UPDATED: "PAYMENT_DETAILS_UPDATED",
    SPEND_MONEY_COMPLETED: "SPEND_MONEY_COMPLETED",
    TAKE_MONEY_COMPLETED: "TAKE_MONEY_COMPLETED",
    GAME_FINALISED: "GAME_FINALISED",
  
    // Finalise game dialog
    ADD_PLAYER_TO_LIST: "ADD_PLAYER_TO_LIST",
    REMOVE_PLAYER_FROM_LIST: "REMOVE_PLAYER_FROM_LIST",
    COURT_FEE_CHANGED: "COURT_FEE_CHANGED",
    SELECTED_GAME_TO_FINALISE: "SELECTED_GAME_TO_FINALISE",
    COURT_PAYER_SELECTED: "COURT_PAYER_SELECTED"
  };
  