import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import React from "react";

function ConfirmDeleteGameDialog(props) {
  const { isDeleteDialogOpen, handleDeleteClosed } = props;

  function handleClosePressed(confirmDelete) {
    handleDeleteClosed(confirmDelete);
  }

  return (
    <Dialog open={isDeleteDialogOpen} onClick={() => handleClosePressed(false)}>
      <DialogTitle id="alert-dialog-title">
        {"Are you sure you want to delete this game?"}
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => handleClosePressed(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => handleClosePressed(true)}
          color="primary"
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDeleteGameDialog;
