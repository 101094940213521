import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Grid,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { withSnackbar } from "notistack";
import React, { useState } from "react";

function TakeMoneyDialog(props) {
  const [open, setOpen] = useState(false);
  const [playerID, setPlayerID] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(0);
  const { enqueueSnackbar, takeMoney, players } = props;

  const activePlayers = players.filter((player) => player.active);

  const handleClickOpen = () => {
    setPlayerID("");
    setDescription("");
    setAmount(0);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTakeMoney = async () => {
    await takeMoney(description, amount, playerID);
    enqueueSnackbar("Created transaction.");
    handleClose();
  };

  const validInput = description && playerID && amount > 0;

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        fullWidth
      >
        Take Money
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Take Money Details</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                id="description"
                label="Description of transaction"
                type="description"
                placeholder="Recieved money from Todd..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Player</InputLabel>
                <Select
                  displayEmpty
                  value={playerID}
                  onChange={(e: any) => {
                    setPlayerID(e.target.value);
                  }}
                >
                  {activePlayers.map((player) => {
                    return (
                      <MenuItem key={player._id} value={player._id}>
                        {player.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="cost"
                label="Amount"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={amount > 0 ? amount : ""}
                onChange={(e) => setAmount(Number(e.target.value))}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!validInput}
            onClick={handleTakeMoney}
            color="primary"
          >
            Take money
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withSnackbar(TakeMoneyDialog);
