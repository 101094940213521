import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import Slide from "@material-ui/core/Slide";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import React from "react";
import { withRouter } from "react-router-dom";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
      color: "#E8EEF1",
    },
    whiteText: {
      color: "#E8EEF1",
    },
  })
);

function NavBar(props) {
  const { profile } = props;
  const history = useHistory();
  const classes = useStyles({});

  const balance = parseFloat(profile.balance).toFixed(2);

  return (
    <>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar>
          <Toolbar>
            <img
              style={{
                // width: "10%",
                marginRight: "3%",
                maxHeight: "50px",
              }}
              src={
                "https://webbx.s3-ap-southeast-2.amazonaws.com/schoolbus.svg"
              }
              alt="PTB Logo"
            />
            <Typography
              className={classes.title}
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/");
              }}
              variant="h6"
            >
              Park the Bus
            </Typography>

            <Typography variant="body1">
              <Link
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => history.push("/profile")}
              >{`$${balance}`}</Link>
            </Typography>

            <Hidden only={["sm", "xs"]}>
              <DesktopMenu profile={profile} />
            </Hidden>
            <Hidden only={["md", "lg", "xl"]}>
              <MobileMenu profile={profile} />
            </Hidden>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      <Container>{props.children}</Container>
    </>
  );
}

function HideOnScroll(props: any) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <>
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    </>
  );
}

export default withRouter(NavBar);
