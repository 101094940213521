import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { createPlayer } from "../../services/api/ptb-server";

function FinaliseProfile(props) {
  const { auth, signupPlayer, logout } = props;
  const [name, setName] = React.useState(auth?.name);
  const [position, setPosition] = React.useState("");
  const [about, setAbout] = React.useState("");
  const [picture, setPicture] = React.useState(props?.auth?.picture);

  async function finaliseProfile() {
    // Add player to player database
    const newPlayer = await createPlayer(
      name,
      auth?.email,
      picture,
      position,
      about
    );
    signupPlayer(newPlayer);
  }

  return (
    <>
      <Grid
        container
        spacing={3}
        style={{ textAlign: "center", maxWidth: "500px", margin: "auto" }}
      >
        <Grid item xs={12}>
          <Typography variant="h1">{`Park the bus`}</Typography>
          <Typography variant="h5">{`Hey ${auth.name}`}</Typography>
          <Typography variant="body1">
            Complete your profile to sign in
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <img src={picture} alt="Profile" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            margin="dense"
            id="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
        </Grid> */}
        <Grid item xs={12}>
          <TextField
            margin="dense"
            id="picture"
            label="Picture URL"
            value={picture}
            onChange={(e) => setPicture(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            id="position"
            label="Position"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            id="about"
            label="About yourself"
            value={about}
            onChange={(e) => setAbout(e.target.value)}
            fullWidth
            multiline
            rows="4"
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" onClick={finaliseProfile} color="primary">
            Finalise Profile
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" onClick={logout} color="primary">
            Log out
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default FinaliseProfile;
