import React from "react";
import ProfileDetails from "./ProfileDetails";
import ProfileHistory from "./ProfileHistory";
import { GlobalStateConsumer } from "../../services/GlobalStateContext";
import { useParams } from "react-router-dom";
import FullWidthPaper from "../Common/PageTemplates/FullWidthPaper";

function ProfileContainer() {
  const { profileid } = useParams();
  return (
    <GlobalStateConsumer>
      {({ state, updateProfile }) => {
        const { transactions, profile, players } = state;

        const profileLookup = players.find((p) => p._id === profileid);
        const profileToLoad = profileLookup ? profileLookup : profile;

        // Get all transactions that belong to the player
        const playerTransactions = transactions
          .filter((t) => t.player === profileToLoad._id)
          .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

        const deductions = playerTransactions.filter((t) => t.amount < 0);
        const payments = playerTransactions.filter((t) => t.amount > 0);
        return (
          <FullWidthPaper heading="Profile">
            <ProfileDetails
              profileToLoad={profileToLoad}
              profile={profile}
              updateProfile={updateProfile}
            />
            <ProfileHistory deductions={deductions} payments={payments} />
          </FullWidthPaper>
        );
      }}
    </GlobalStateConsumer>
  );
}

export default ProfileContainer;
