import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";

function EditGameDialog(props) {
  const [name, setName] = useState("");
  const [gameTime, setGameTime] = useState(format(new Date(), "HH:mm"));
  const [gameDate, setGameDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [venueID, setVenueID] = useState("");
  const {
    gameToEdit,
    isEditGameDialogOpen,
    handleEditGameClosed,
    venues,
  } = props;

  const activeVenues = venues.filter((venue) => venue.active);

  useEffect(() => {
    if (gameToEdit) {
      setName(gameToEdit.name);
      setGameTime(format(new Date(gameToEdit.date), "HH:mm"));
      setGameDate(format(new Date(gameToEdit.date), "yyyy-MM-dd"));
      setVenueID(gameToEdit.venue);
    }
  }, [gameToEdit]);

  function handleClosePressed(confirmSave) {
    if (confirmSave) {
      let gameDateTime = new Date(gameDate);
      const hours = parseInt(gameTime.split(":")[0]);
      const mins = parseInt(gameTime.split(":")[1]);
      gameDateTime.setHours(hours);
      gameDateTime.setMinutes(mins);
      const newGameState = {
        ...gameToEdit,
        name,
        date: gameDateTime.toISOString(),
        venue: venueID,
      };
      handleEditGameClosed(confirmSave, newGameState);
    } else {
      handleEditGameClosed(confirmSave);
    }
  }

  return (
    <Dialog
      fullWidth
      open={isEditGameDialogOpen}
      //   onClick={() => handleClosePressed(false)}
    >
      <DialogTitle>{`Editing ${gameToEdit?.name}`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Name"
              fullWidth
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="date"
              label="Date"
              type="date"
              value={gameDate}
              fullWidth
              onChange={(e) => setGameDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="time"
              label="Time"
              type="time"
              value={gameTime}
              onChange={(e) => setGameTime(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="position-input-label">Venue</InputLabel>
              <Select
                labelId="position-input-label"
                id="position-select"
                value={venueID}
                onChange={(e: any) => setVenueID(e.target.value)}
              >
                {activeVenues.map((venue) => {
                  return (
                    <MenuItem key={venue._id} value={venue._id}>
                      {venue.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClosePressed(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => handleClosePressed(true)}
          color="primary"
          autoFocus
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditGameDialog;
