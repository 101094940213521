import React from "react";
import BusGif from "../../assets/images/busgif.webp";
import { Typography, Grid } from "@material-ui/core";

function LoadingBus() {
  return (
    <>
      <Grid container justify="center" style={{ marginTop: "5%" }}>
        <Typography variant="caption">Starting the bus...</Typography>
      </Grid>

      <Grid container justify="center">
        <img
          src={BusGif}
          alt="Loading Spinner"
          style={{ width: "75%", maxWidth: "450px", padding: "5%" }}
        />
      </Grid>
    </>
  );
}

export default LoadingBus;
