import { Button, IconButton } from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import DateRangeIcon from "@material-ui/icons/DateRange";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";

function DesktopMenu(props) {
  const { profile } = props;
  const { logout } = useAuth0();
  const history = useHistory();

  let NAV_ITEMS = [
    {
      route: "/players",
      icon: <PeopleIcon style={{ fill: "white" }} />,
    },
    // {
    //   route: "/profile",
    //   icon: <AccountCircleIcon style={{ fill: "white" }} />,
    // },
    {
      route: "/games",
      icon: <DateRangeIcon style={{ fill: "white" }} />,
    },
  ];
  if (profile.admin) {
    NAV_ITEMS.push({
      route: "/admin",
      icon: <SettingsIcon style={{ fill: "white" }} />,
    });
    NAV_ITEMS.push({
      route: "/venues",
      icon: <BusinessIcon style={{ fill: "white" }} />,
    });
  }
  return (
    <>
      {NAV_ITEMS.map((item, index) => {
        return (
          <IconButton
            key={index}
            onClick={() => {
              history.push(item.route);
            }}
          >
            {item.icon}
          </IconButton>
        );
      })}
      <Button
        onClick={() => logout({ returnTo: window.location.origin })}
        style={{ color: "white" }}
      >
        Logout
      </Button>
    </>
  );
}
export default withRouter(DesktopMenu);
