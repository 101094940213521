import {
  Avatar,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import { withSnackbar } from "notistack";
import React, { useEffect, useReducer } from "react";
import EmptyProfilePic from "../../assets/images/profile-empty.jpg";
import { actions } from "../../utils/actions";
import * as utils from "../../utils/utils";

/*
  WHAT THE FU CK SHOULD HAPPEN WHEN YOU FINALISE A GAME?
  FRONTEND
  1. Add or remove players to capture those that attended
  2. Set court cost and who paid for it
  3. Calculate cost per person = (court_cost + players.length * additional_fee) / players.length
  
  BACKEND
  1. Delete all attendance for the game and re-add all players
  2. Create transactions for each player and debit them the calculated cost per person
  3. Create a transaction for the player that paid the court fee, crediting them the calculated cost per person
  4. Mark game as finalised
  */

function reducer(state, action) {
  switch (action.type) {
    case actions.SELECTED_GAME_TO_FINALISE:
      return {
        ...state,
        playersOnGame: action.value.playersOnGame,
        playersOffGame: action.value.playersOffGame,
        gameid: action.value.gameid,
        costPerPerson: action.value.costPerPerson,
      };
    case actions.ADD_PLAYER_TO_LIST:
      const newPlayersOnGame = [...state.playersOnGame, { ...action.value }];
      const newAmountPerPerson = utils.roundFloat(
        state.courtFee / newPlayersOnGame.length
      );
      return {
        ...state,
        playersOnGame: [...state.playersOnGame, action.value],
        costPerPerson: utils.roundFloat(newAmountPerPerson),
        playersOffGame: state.playersOffGame.filter(
          (player) => player._id !== action.value._id
        ),
      };
    case actions.REMOVE_PLAYER_FROM_LIST:
      const newPlayersOnGameState = state.playersOnGame.filter(
        (player) => player._id !== action.value._id
      );
      const newAmount = utils.roundFloat(
        state.courtFee / newPlayersOnGameState.length
      );
      return {
        ...state,
        costPerPerson: newAmount,
        playersOnGame: newPlayersOnGameState,
        playersOffGame: [...state.playersOffGame, action.value],
      };
    case actions.COURT_FEE_CHANGED:
      const newCostPerPerson = utils.roundFloat(
        action.value / state.playersOnGame.length
      );
      return {
        ...state,
        costPerPerson: newCostPerPerson,
        courtFee: action.value,
      };
    case actions.COURT_PAYER_SELECTED:
      return {
        ...state,
        whoPaidCourt: action.value,
      };
    default:
      return {
        ...state,
      };
  }
}

const INITIAL_STATE = {
  status: utils.state.READY,
  gameid: null,
  playersOnGame: [],
  playersOffGame: [],
  courtFee: 50,
  whoPaidCourt: "",
  costPerPerson: 0,
};

function FinaliseGameDialog(props) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const {
    isFinaliseDialogOpen,
    handleFinaliseClosed,
    selectedGame,
    enqueueSnackbar,
    attendance,
    players,
    finaliseGame,
  } = props;

  const activePlayers = players.filter((player) => player.active);

  // This effect runs the first time the dialog is visible
  useEffect(() => {
    if (isFinaliseDialogOpen) {
      const newPlayersOnGame = attendance
        .filter((atten) => atten.gameid === selectedGame._id)
        .map((atten) => {
          return players.find((player) => player._id === atten.playerid);
        });
      const newPlayersOffGame = activePlayers.filter(
        (player) => !newPlayersOnGame.map((p) => p._id).includes(player._id)
      );
      const initialCostPerPerson = utils.roundFloat(
        INITIAL_STATE.courtFee / newPlayersOnGame.length
      );

      dispatch({
        type: actions.SELECTED_GAME_TO_FINALISE,
        value: {
          gameid: selectedGame._id,
          playersOnGame: newPlayersOnGame,
          playersOffGame: newPlayersOffGame,
          costPerPerson: initialCostPerPerson,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinaliseDialogOpen, attendance, players, selectedGame]);

  function handleClose() {
    handleFinaliseClosed();
  }

  async function handleFinaliseGame() {
    const finaliseResult = await finaliseGame(
      state.gameid,
      state.playersOnGame,
      state.whoPaidCourt,
      state.courtFee
    );

    if (finaliseResult) {
      enqueueSnackbar("Game finalised.");
      handleFinaliseClosed();
    } else {
      enqueueSnackbar("Error finalising game.");
    }
  }

  function handlePlayerAddedToGame(playerid) {
    const player = players.find((player) => player._id === playerid);
    dispatch({ type: actions.ADD_PLAYER_TO_LIST, value: player });
  }
  function handlePlayerRemovedFromGame(playerid) {
    const player = players.find((player) => player._id === playerid);
    dispatch({ type: actions.REMOVE_PLAYER_FROM_LIST, value: player });
  }
  function handleWhoPaidCourtSelected(playerid) {
    dispatch({ type: actions.COURT_PAYER_SELECTED, value: playerid });
  }

  const calculationText =
    state.playersOnGame.length > 0
      ? `${state.playersOnGame.length} Players attended the game at $${state.costPerPerson} each.`
      : "Add a player to finalise the game";

  return (
    <Dialog maxWidth="sm" open={isFinaliseDialogOpen} onClose={handleClose}>
      <DialogTitle>{`Finalise ${selectedGame?.name}`}</DialogTitle>
      <DialogContent style={{ width: "100%" }}>
        <Typography variant="subtitle2">{calculationText}</Typography>
        <List>
          {state.playersOnGame.map((player) => {
            return (
              <ListItem key={player._id} alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    alt={player.name}
                    src={player.picture ? player.picture : EmptyProfilePic}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={player.name}
                  secondary={player.position}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={(e) => handlePlayerRemovedFromGame(player._id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
        <FormControl fullWidth>
          <InputLabel>Add Player</InputLabel>
          <Select
            value=""
            displayEmpty
            onChange={(e) => {
              handlePlayerAddedToGame(e.target.value);
            }}
          >
            {state.playersOffGame.map((player) => {
              return (
                <MenuItem key={player._id} value={player._id}>
                  {player.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Who paid for the court/venue?</InputLabel>
          <Select
            value={state.whoPaidCourt}
            displayEmpty
            onChange={(event, selection: any) => {
              handleWhoPaidCourtSelected(selection.key);
            }}
          >
            {activePlayers.map((player) => {
              return (
                <MenuItem key={player._id} value={player._id}>
                  {player.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          margin="dense"
          id="cost"
          label="Court cost"
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          value={state.courtFee}
          onChange={(e) => {
            const newCourtFee = Number(e.target.value);
            dispatch({ type: actions.COURT_FEE_CHANGED, value: newCourtFee });
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => handleFinaliseGame()}
          disabled={!state.whoPaidCourt || state.playersOnGame.length === 0}
          color="primary"
        >
          Finalise Game
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withSnackbar(FinaliseGameDialog);
