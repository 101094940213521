import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";

function MobileMenu(props) {
  const { profile } = props;
  const { logout } = useAuth0();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (newScreen) => {
    setAnchorEl(null);
  };

  const handleItemSelected = (screen) => {
    setAnchorEl(null);
    history.push(`/${screen}`);
  };

  return (
    <div>
      <Button onClick={handleClick} style={{ color: "white" }}>
        Menu
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          style={{ display: profile.admin ? "" : "none" }}
          onClick={() => handleItemSelected("admin")}
        >
          Admin
        </MenuItem>
        <MenuItem
          style={{ display: profile.admin ? "" : "none" }}
          onClick={() => handleItemSelected("venues")}
        >
          Venues
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleItemSelected("players");
          }}
        >
          Players
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleItemSelected("profile");
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleItemSelected("games");
          }}
        >
          Games
        </MenuItem>
        <MenuItem
          onClick={() =>
            logout({
              returnTo: window.location.origin,
            })
          }
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}

export default MobileMenu;
