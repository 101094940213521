import React, { useEffect, useReducer } from "react";
import { actions } from "../utils/actions";
import * as utils from "../utils/utils";
import * as apiClient from "./api/ptb-server";
import { useAuth0 } from "../react-auth0-spa";

const GlobalStateContext = React.createContext();

function reducer(state, action) {
  switch (action.type) {
    case actions.INITIAL_DATA_LOADED:
      return {
        ...state,
        auth: action.value.auth,
        profile: action.value.profile,
        games: action.value.games,
        venues: action.value.venues,
        attendance: action.value.attendance,
        players: action.value.players,
        status: action.value.status,
        transactions: action.value.transactions,
        appConfig: action.value.appConfig,
      };
    case actions.EDIT_PLAYER:
      return {
        ...state,
        players: state.players.map((player) => {
          if (player._id === action.value._id) {
            return action.value;
          } else {
            return player;
          }
        }),
      };
    case actions.NEW_PLAYER_SIGNUP:
      return {
        ...state,
        profile: action.value.result,
        players: [...state.players, action.value],
        status: utils.state.AWAITING_APPROVAL,
      };
    case actions.ADD_PLAYER_TO_GAME:
      return {
        ...state,
        attendance: [...state.attendance, action.value],
      };
    case actions.REMOVE_PLAYER_FROM_GAME:
      return {
        ...state,
        attendance: state.attendance.filter((att) => {
          if (
            att.gameid === action.value.game &&
            att.playerid === action.value.player
          ) {
            return false;
          } else {
            return true;
          }
        }),
      };
    case actions.DELETE_PLAYER:
      return {
        ...state,
        players: state.players.filter((player) => player._id !== action.value),
      };
    case actions.PROFILE_UPDATED:
      return {
        ...state,
        profile: action.value,
        players: state.players.map((player) => {
          if (player._id === action.value._id) {
            return action.value;
          } else {
            return player;
          }
        }),
      };
    case actions.GAME_CREATED:
      return {
        ...state,
        games: [...state.games, action.value],
      };
    case actions.GAME_DELETED:
      return {
        ...state,
        games: state.games.filter((game) => game._id !== action.value),
      };
    case actions.GAME_UPDATED:
      return {
        ...state,
        games: state.games.map((game) => {
          if (game._id === action.value._id) {
            return action.value;
          } else {
            return game;
          }
        }),
      };
    case actions.VENUE_UPDATED:
      return {
        ...state,
        venues: state.venues.map((venue) => {
          if (venue._id === action.value._id) {
            return action.value;
          } else {
            return venue;
          }
        }),
      };
    case actions.VENUE_CREATED:
      return {
        ...state,
        venues: [...state.venues, action.value],
      };

    case actions.PAYMENT_DETAILS_UPDATED:
      return {
        ...state,
        appConfig: {
          ...state.appConfig,
          paymentDetails: action.value,
        },
      };
    case actions.SPEND_MONEY_COMPLETED:
      const newPlayersState = [...state.players];
      const newTransactions = [...state.transactions, ...action.value];

      newPlayersState.forEach((player) => {
        const playerBalance = newTransactions.reduce((acc, transaction) => {
          if (transaction.player === player._id) {
            acc += transaction.amount;
          }
          return acc;
        }, 0);
        player.balance = parseFloat(playerBalance).toFixed(2);
      });
      return {
        ...state,
        transactions: newTransactions,
        players: newPlayersState,
      };

    case actions.TAKE_MONEY_COMPLETED:
      const newPlayersState2 = [...state.players];
      const newTransactions2 = [...state.transactions, action.value];
      newPlayersState2.forEach((player) => {
        const playerBalance = newTransactions2.reduce((acc, transaction) => {
          if (transaction.player === player._id) {
            acc += transaction.amount;
          }
          return acc;
        }, 0);
        player.balance = parseFloat(playerBalance).toFixed(2);
      });
      return {
        ...state,
        transactions: newTransactions2,
        players: newPlayersState2,
      };

    case actions.GAME_FINALISED:
      const newPlayersState3 = [...state.players];
      const newTransactions3 = [...action.value.transactions];
      newPlayersState3.forEach((player) => {
        const playerBalance = newTransactions3.reduce((acc, transaction) => {
          if (transaction.player === player._id) {
            acc += transaction.amount;
          }
          return acc;
        }, 0);
        player.balance = parseFloat(playerBalance).toFixed(2);
      });

      return {
        ...state,
        transactions: newTransactions3,
        attendance: action.value.attendance,
        games: action.value.games,
      };

    default:
      return { ...state };
  }
}

const INITIAL_STATE = {
  status: utils.state.LOADING,
  players: [],
  venues: [],
  games: [],
  transactions: [],
  attendance: [],
  expenses: [],
  appConfig: null,
  profile: null, // PTB profile from Players table
  auth: null, // Authentication service profile
};

export function GlobalContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { getTokenSilently } = useAuth0();
  const { user } = props;

  useEffect(() => {

    async function getInitialData() {
      const token = await getTokenSilently();

      apiClient.setToken(token);

      Promise.allSettled([
        apiClient.getGames(),
        apiClient.getPlayers(),
        apiClient.getVenues(),
        apiClient.getAttendance(),
        apiClient.getTransactions(),
        apiClient.getAppConfig(),
      ]).then(
        async ([
          games,
          players,
          venues,
          attendance,
          transactions,
          appConfig,
        ]) => {
          if (process.env.REACT_APP_ENVIRONMENT === "local") {
            console.log("games: %o", games.value.data);
            console.log("players: %o", players.value.data);
            console.log("venues: %o", venues.value.data);
            console.log("Attendance: %o", attendance.value.data);
            console.log("Transactions: %o", transactions.value.data);
            console.log("App Config: %o", appConfig.value.data);
          }


          const profile = players.value.data.find(
            (player) => player.email.trim().toLowerCase() === user.email.trim().toLowerCase()
          );

          // Add balances to players
          players.value.data.forEach((player) => {
            const playerBalance = transactions.value.data.reduce(
              (acc, transaction) => {
                if (transaction.player === player._id) {
                  acc += transaction.amount;
                }
                return acc;
              },
              0
            );
            player.balance = parseFloat(playerBalance).toFixed(2);
          });

          let status = utils.state.READY;
          if (!profile) {
            status = utils.state.FINALISE_SIGNUP;
          } else if (!profile.active) {
            status = utils.state.AWAITING_APPROVAL;
          }

          dispatch({
            type: actions.INITIAL_DATA_LOADED,
            value: {
              games: games.value.data,
              players: players.value.data,
              venues: venues.value.data,
              attendance: attendance.value.data,
              transactions: transactions.value.data,
              appConfig: appConfig.value.data[0],
              profile: profile,
              auth: user,
              status,
            },
          });
        }
      );
    }
    getInitialData();
    // apiClient.setToken(getToken());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // async function getToken() {
  //   return await getAccessTokenSilently({
  //     audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  //     scope: process.env.REACT_APP_AUTH0_SCOPE,
  //   });
  // }

  async function spendMoney(playerids, amount, name) {
    const newTransactions = await apiClient.spendMoney(playerids, amount, name);
    dispatch({
      type: actions.SPEND_MONEY_COMPLETED,
      value: newTransactions.data,
    });
    return newTransactions.data.length;
  }

  async function updatePaymentDetails(newPaymentDetails) {
    const newPaymentDetailsResult = await apiClient.updatePaymentDetails(
      state.appConfig._id,
      newPaymentDetails
    );
    dispatch({
      type: actions.PAYMENT_DETAILS_UPDATED,
      value: newPaymentDetails,
    });
    if (newPaymentDetailsResult.count === 1) {
      return true;
    } else {
      return false;
    }
  }

  async function addPlayerToGame(playerid, gameid) {
    const addPlayerResult = await apiClient.addPlayerToGame(playerid, gameid);
    dispatch({
      type: actions.ADD_PLAYER_TO_GAME,
      value: addPlayerResult.result,
    });
  }

  function removePlayerFromGame(playerid, gameid) {
    return apiClient
      .removePlayerFromGame(playerid, gameid)
      .then((result) => {
        dispatch({
          type: actions.REMOVE_PLAYER_FROM_GAME,
          value: { player: playerid, game: gameid },
        });
      })
      .catch((err) => console.error(err));
  }

  function createGame(name, date, time, venueID, courtfee) {
    let gameDateTime = new Date(date);
    const hours = time.split(":")[0];
    const mins = time.split(":")[1];
    gameDateTime.setHours(hours);
    gameDateTime.setMinutes(mins);

    const newGame = {
      name,
      date: gameDateTime.toISOString(),
      venue: venueID,
      courtfee,
      additionalFee: 0,
    };

    return apiClient
      .createGame(newGame)
      .then((result) => {
        dispatch({ type: actions.GAME_CREATED, value: result.data });
        return true;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  }

  function deleteGame(gameid) {
    return apiClient
      .deleteGame(gameid)
      .then((result) => {
        dispatch({ type: actions.GAME_DELETED, value: gameid });
        return true;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  }

  function updateProfile(playerid, name, email, picture, position, about) {
    const newProfileState = {
      position,
      name,
      about,
      email,
      picture,
    };
    return apiClient
      .updatePlayer(playerid, newProfileState)
      .then((updatedProfile) => {
        const newProfile = { ...state.profile, ...newProfileState };
        dispatch({ type: actions.PROFILE_UPDATED, value: newProfile });
        // setProfile({ ...profile, position, name, about });
        // setPlayers([
        //   players.map((p) => {
        //     if (p.playerid === playerid) {
        //       return { ...p, position, name, about };
        //     }
        //     return p;
        //   }),
        // ]);
        return true;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  }

  async function takeMoney(transactionName, amount, player) {
    const newTransaction = await apiClient.takeMoney(
      transactionName,
      amount,
      player
    );
    dispatch({
      type: actions.TAKE_MONEY_COMPLETED,
      value: newTransaction.result,
    });
  }

  async function finaliseGame(gameid, playersOnGame, whoPaidCourt, courtFee) {
    const players = playersOnGame.map((player) => player._id);
    const finaliseResult = await apiClient.finaliseGame(
      gameid,
      players,
      whoPaidCourt,
      courtFee
    );
    if (finaliseResult) {
      Promise.allSettled([
        apiClient.getGames(),
        apiClient.getAttendance(),
        apiClient.getTransactions(),
      ]).then(async ([games, attendance, transactions]) => {
        dispatch({
          type: actions.GAME_FINALISED,
          value: {
            games: games.value.data,
            attendance: attendance.value.data,
            transactions: transactions.value.data,
          },
        });
      });
    }
    return finaliseResult;
  }

  async function activatePlayer(playerid) {
    const result = await apiClient.activatePlayer(playerid);
    if (result.count === 1) {
      const playerLookup = state.players.find(
        (player) => player._id === playerid
      );
      playerLookup.active = true;
      dispatch({ type: actions.EDIT_PLAYER, value: playerLookup });
      return true;
    } else {
      console.error("Error activating player.");
      return false;
    }
  }

  async function rejectPlayer(playerid) {
    const result = await apiClient.rejectPlayer(playerid);
    if (result.count !== 0) {
      dispatch({ type: actions.DELETE_PLAYER, value: playerid });
      return true;
    } else {
      console.error("Error rejecting player.");
      return false;
    }
  }

  async function updateGame(newGameState) {
    const result = await apiClient.updateGame(newGameState);
    if (result.count === 1) {
      dispatch({ type: actions.GAME_UPDATED, value: newGameState });
      return true;
    } else {
      console.error("Error updating game");
      return false;
    }
  }

  async function createVenue(newVenueState) {
    const newVenue = await apiClient.createVenue(newVenueState);
    if (newVenue.result) {
      dispatch({ type: actions.VENUE_CREATED, value: newVenue.result });
      return true;
    } else {
      console.error("Error creating venue");
      return false;
    }
  }

  async function updateVenue(newVenueState) {
    const result = await apiClient.updateVenue(newVenueState);
    if (result.count === 1) {
      dispatch({ type: actions.VENUE_UPDATED, value: newVenueState });
      return true;
    } else {
      console.error("Error updating venue");
      return false;
    }
  }

  function signupPlayer(player) {
    dispatch({ type: actions.NEW_PLAYER_SIGNUP, value: player });
  }

  return (
    <GlobalStateContext.Provider
      value={{
        state,
        signupPlayer,
        addPlayerToGame,
        removePlayerFromGame,
        updateProfile,
        createGame,
        finaliseGame,
        deleteGame,
        activatePlayer,
        rejectPlayer,
        updateGame,
        updateVenue,
        createVenue,
        updatePaymentDetails,
        takeMoney,
        spendMoney,
      }}
    >
      <>
        {process.env.REACT_APP_ENVIRONMENT === "local" &&
          console.log("Rendering with global state: %o", state)}
        {props.children}
      </>
    </GlobalStateContext.Provider>
  );
}

export const GlobalStateConsumer = GlobalStateContext.Consumer;
