import React from "react";
import { format } from "date-fns";
import { List, ListItem, Grid, Divider } from "@material-ui/core";

function DeductionsList(props) {
  const { deductions } = props;
  return (
    <List>
      {deductions.map((deduction) => {
        return (
          <div key={deduction._id}>
            <ListItem disableGutters>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {deduction.name}
                </Grid>
                <Grid item xs={6} style={{ color: "Red" }}>
                  ${parseFloat(deduction.amount).toFixed(2)}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {`${format(new Date(deduction.createdAt), "d/MM/yyyy")}`}
                </Grid>
              </Grid>
              <Divider />
            </ListItem>
          </div>
        );
      })}
    </List>
  );
}

export default DeductionsList;
