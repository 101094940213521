import { isBefore } from "date-fns";
import React from "react";
import { GlobalStateConsumer } from "../../services/GlobalStateContext";
import FullWidthPaper from "../Common/PageTemplates/FullWidthPaper";
import UpcomingGames from "./UpcomingGames";

function HomeContainer() {
  return (
    <GlobalStateConsumer>
      {({ state, addPlayerToGame, removePlayerFromGame }) => {
        const { venues, games, profile, players, appConfig } = state;

        const upcomingGames = games.filter((game) =>
          isBefore(new Date(), new Date(game.date))
        );
        const upcomingGameIds = upcomingGames.map((game) => game._id);
        const upcomingAttendance = state.attendance.filter((attendance) => {
          return upcomingGameIds.includes(attendance.gameid);
        });
        return (
          <>
            <FullWidthPaper heading="Upcoming Games">
              <>
                <UpcomingGames
                  attendance={upcomingAttendance}
                  venues={venues}
                  upcomingGames={upcomingGames}
                  profile={profile}
                  players={players}
                  addPlayerToGame={addPlayerToGame}
                  removePlayerFromGame={removePlayerFromGame}
                />
                <div
                  dangerouslySetInnerHTML={{ __html: appConfig.paymentDetails }}
                />
              </>
            </FullWidthPaper>
          </>
        );
      }}
    </GlobalStateConsumer>
  );
}

export default HomeContainer;
