import React, { useState } from "react";
import EditVenueDialog from "./EditVenueDialog";
import {
  Grid,
  Typography,
  Button,
  Divider,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { withSnackbar } from "notistack";

let venueToEdit = null;
function VenuesList(props) {
  const [isEditVenueDialogOpen, setIsEditVenueDialogOpen] = useState(false);
  const { venues, updateVenue, enqueueSnackbar } = props;

  const buttonStyle = { margin: "5px 15px" };

  function handleEditVenuePressed(venue) {
    venueToEdit = venue;
    setIsEditVenueDialogOpen(true);
  }

  async function handleEditVenueClosed(confirmSave, newVenueState) {
    if (confirmSave) {
      const result = await updateVenue(newVenueState);
      if (result) {
        enqueueSnackbar(`Updated ${newVenueState.name}`);
      }
    }
    venueToEdit = null;
    setIsEditVenueDialogOpen(false);
  }

  return (
    <>
      <Grid container spacing={3}>
        {venues.map((venue) => {
          return (
            <Grid key={venue._id} container spacing={3} item xs={12}>
              <Grid item xs={9}>
                <Typography variant="h5">{venue.name}</Typography>
                <Typography variant="body1">{venue.address}</Typography>
                <Typography variant="body2">{venue.phone}</Typography>
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Switch color="primary" />}
                  checked={venue.active}
                  label="Active"
                  labelPlacement="top"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => handleEditVenuePressed(venue)}
                  variant="outlined"
                  color="primary"
                  style={buttonStyle}
                >
                  Edit
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <EditVenueDialog
        handleEditVenueClosed={handleEditVenueClosed}
        isEditVenueDialogOpen={isEditVenueDialogOpen}
        venueToEdit={venueToEdit}
      />
    </>
  );
}

export default withSnackbar(VenuesList);
