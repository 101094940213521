import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useAuth0 } from "../../react-auth0-spa";

function AwaitingApproval(props) {
  const { profile } = props;
  const { logout } = useAuth0();
  return (
    <Grid
      container
      spacing={3}
      style={{ maxWidth: "500px", textAlign: "center", margin: "auto" }}
    >
      <Grid item xs={12}>
        <Typography variant="h4">
          Thanks for signing up {profile.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          Your team manager has been notified and will add you to the team
          shortly.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={() =>
            logout({
              returnTo: window.location.origin,
            })
          }
          color="primary"
        >
          Log out
        </Button>
      </Grid>
    </Grid>
  );
}

export default AwaitingApproval;
