import React from "react";
import Admin from "./Admin";
import FullWidthPaper from "./../Common/PageTemplates/FullWidthPaper";
import { GlobalStateConsumer } from "../../services/GlobalStateContext";

function AdminContainer() {
  return (
    <GlobalStateConsumer>
      {({
        state,
        createGame,
        createTransaction,
        finaliseGame,
        updatePaymentDetails,
        spendMoney,
        takeMoney
      }) => {
        const {
          games,
          expenses,
          venues,
          players,
          attendance,
          appConfig,
        } = state;
        return (
          <FullWidthPaper heading="Admin">
            <Admin
              games={games}
              venues={venues}
              expenses={expenses}
              players={players}
              attendance={attendance}
              createGame={createGame}
              createTransaction={createTransaction}
              finaliseGame={finaliseGame}
              paymentDetails={appConfig.paymentDetails}
              updatePaymentDetails={updatePaymentDetails}
              spendMoney={spendMoney}
              takeMoney={takeMoney}
            />
          </FullWidthPaper>
        );
      }}
    </GlobalStateConsumer>
  );
}

export default AdminContainer;
