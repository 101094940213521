import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { withSnackbar } from "notistack";
import React, { useState } from "react";

function EditProfileDialog(props) {
  const { profile, updateProfile, enqueueSnackbar } = props;
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState(profile.position);
  const [name, setName] = useState(profile.name);
  const [about, setAbout] = useState(profile.about);
  const [picture, setPicture] = useState(profile.picture);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdatePressed = async () => {
    await updateProfile(
      profile._id,
      name,
      profile.email,
      picture,
      position,
      about
    );
    enqueueSnackbar("Profile updated.");
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Edit Profile
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Profile</DialogTitle>
        <DialogContent style={{ height: "100vw" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email"
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="position"
                label="Position"
                defaultValue={position}
                onChange={(e) => setPosition(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="about"
                label="About"
                defaultValue={about}
                onChange={(e) => setAbout(e.target.value)}
                fullWidth
                multiline
                rows="4"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="picture"
                label="Picture"
                defaultValue={picture}
                onChange={(e) => setPicture(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Preview</Typography>
              <img src={picture} alt="Preview" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdatePressed} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withSnackbar(EditProfileDialog);
