import { Container, Divider, Grid } from "@material-ui/core";
import React from "react";
import PaymentDetails from "./PaymentDetails";
import SpendMoneyDialog from "./SpendMoneyDialog";
import TakeMoneyDialog from "./TakeMoneyDialog";

function Admin(props) {
  const {
    players,
    paymentDetails,
    updatePaymentDetails,
    spendMoney,
    takeMoney,
  } = props;

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <TakeMoneyDialog takeMoney={takeMoney} players={players} />
        </Grid>
        <Grid item xs={12} md={3}>
          <SpendMoneyDialog players={players} spendMoney={spendMoney} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <PaymentDetails
            paymentDetails={paymentDetails}
            updatePaymentDetails={updatePaymentDetails}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Admin;
