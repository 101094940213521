import React from "react";
import "./App.css";
import LoadingBall from "./components/Common/LoadingBus";
import NavBar from "./components/NavBar/NavBar";
import Routes from "./components/Routes";
import AwaitingApproval from "./components/SignIn/AwaitingApproval";
import FinaliseProfile from "./components/SignIn/FinaliseProfile";
import SignIn from "./components/SignIn/SignInContainer";
import { useAuth0 } from "./react-auth0-spa";
import {
  GlobalContextProvider,
  GlobalStateConsumer,
} from "./services/GlobalStateContext";
import * as utils from "./utils/utils";
import ErrorBoundary from "./utils/ErrorBoundary";

function App() {
  const { loading, isAuthenticated, user, logout } = useAuth0();
  console.log(`Loading Park the bus: ${process.env.REACT_APP_ENVIRONMENT}`);

  if (loading) {
    return <LoadingBall />;
  }

  if (!isAuthenticated) {
    return <SignIn />;
  }

  return (
    <ErrorBoundary>
      <GlobalContextProvider user={user}>
        <GlobalStateConsumer>
          {({ state, signupPlayer }) => {
            const { profile, auth, status } = state;
            if (status === utils.state.LOADING) {
              return;
            }

            if (status === utils.state.FINALISE_SIGNUP) {
              return (
                <FinaliseProfile
                  auth={auth}
                  logout={logout}
                  signupPlayer={signupPlayer}
                />
              );
            }

            if (status === utils.state.AWAITING_APPROVAL) {
              return <AwaitingApproval profile={profile} />;
            }
            if (state.profile && state.status === utils.state.READY) {
              return (
                <NavBar profile={profile} transactions={state.transactions}>
                  <Routes />
                </NavBar>
              );
            } else {
              return <LoadingBall />;
            }
          }}
        </GlobalStateConsumer>
      </GlobalContextProvider>
    </ErrorBoundary>
  );
}
export default App;
