import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { GlobalStateConsumer } from "../../services/GlobalStateContext";
import CreateGameDialog from "../Admin/CreateGameDialog";
import FullWidthPaper from "../Common/PageTemplates/FullWidthPaper";
import GamesList from "./GamesList";

function GamesContainer() {
  return (
    <GlobalStateConsumer>
      {({
        state,
        createGame,
        addPlayerToGame,
        removePlayerFromGame,
        deleteGame,
        finaliseGame,
        updateGame,
      }) => {
        const { venues, games, profile, players, attendance } = state;

        const sortedGames = games.sort((a, b) => (a.date < b.date ? 1 : -1));
        const hideIfNotAdmin = { display: profile.admin ? "" : "none" };
        return (
          <>
            <FullWidthPaper heading="Games">
              <Grid container>
                <Grid item xs={12} md={2} style={hideIfNotAdmin}>
                  <CreateGameDialog venues={venues} createGame={createGame} />
                </Grid>
                <Grid item xs={12} style={hideIfNotAdmin}>
                  <Divider style={{ margin: "15px 0" }} />
                </Grid>

                <Grid item xs={12}>
                  <GamesList
                    venues={venues}
                    profile={profile}
                    attendance={attendance}
                    games={sortedGames}
                    players={players}
                    addPlayerToGame={addPlayerToGame}
                    removePlayerFromGame={removePlayerFromGame}
                    deleteGame={deleteGame}
                    finaliseGame={finaliseGame}
                    updateGame={updateGame}
                  />
                </Grid>
              </Grid>
            </FullWidthPaper>
          </>
        );
      }}
    </GlobalStateConsumer>
  );
}

export default GamesContainer;
