import React, { useState, useEffect } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { withSnackbar } from "notistack";

// This will use div instead of paragraphs, fixing email formatting
const Block = Quill.import("blots/block");
Block.tagName = "DIV";

Quill.register(Block, true);

function PaymentDetails(props) {
  const [newPaymentDetails, setNewPaymentDetails] = useState(null);
  const { paymentDetails, updatePaymentDetails, enqueueSnackbar } = props;

  function handlePaymentDetailsChange(newDetails) {
    setNewPaymentDetails(newDetails);
  }

  async function handlePaymentDetailsUpdate() {
    const updateResult = await updatePaymentDetails(newPaymentDetails);
    if (updateResult) {
      enqueueSnackbar("Payment details updated.");
    } else {
      enqueueSnackbar("Error updating payment details.");
    }
  }

  useEffect(() => {
    setNewPaymentDetails(paymentDetails);
  }, [paymentDetails]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  if (!newPaymentDetails) {
    return <div>Loading...</div>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Payment Details</Typography>
      </Grid>

      <Grid item xs={12}>
        <ReactQuill
          style={{ backgroundColor: "White" }}
          value={newPaymentDetails}
          theme="snow"
          modules={modules}
          onChange={handlePaymentDetailsChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={handlePaymentDetailsUpdate}
          color="primary"
          variant="outlined"
        >
          Update Payment Details
        </Button>
      </Grid>
    </Grid>
  );
}

export default withSnackbar(PaymentDetails);
